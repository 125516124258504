import axios from "axios";
import router from "@/router";
import { getAbortCtrlSignal } from "@/services/abort.services";

function getPrograms() {
  return axios.get(`${router.options.base}api/programs`);
}
function getOrders() {
  return axios.get(`${router.options.base}api/findCp`, {
    signal: getAbortCtrlSignal()
  });
}
function getById(cpId, authEsa) {
  if (authEsa) {
    return axios.get(`${router.options.base}esa/findCp?cpId=${cpId}`);
  } else {
    return axios.get(`${router.options.base}api/findCp?cpId=${cpId}`);
  }
}

function getEsaOrders(data) {
  return axios.post(`${router.options.base}esa/findCp`, data, {
    signal: getAbortCtrlSignal()
  });
}

function getEsaOrdersDefaultOptions() {
  return axios.get(`${router.options.base}esa/defaults`);
}
function getHistory(cpId, authEsa) {
  if (authEsa) {
    return axios.get(`${router.options.base}esa/history?cpId=${cpId}`);
  } else {
    return axios.get(`${router.options.base}api/history?cpId=${cpId}`);
  }
}

function termFilter(term) {
  let string = "";
  let month = term.replace("M", "");

  if (term === "M24" || term === "M72") {
    string = `${month} ${this.$i18n.t("options.month24")}`;
  } else {
    string = `${month} ${this.$i18n.t("options.month12")}`;
  }

  return string;
}

function createDataForTableOrders(data, authEsa) {
  return data.map((item, idx) => {
    let _state, _program;
    let _seller = "";

    if (item?.hasUaJournalState) {
      _state = item.uaJournalState;
    } else {
      _state = this.$i18n.t(`orders.state.${item.state}`);
    }
    if (authEsa) {
      _seller = `${item.orgOkpo} ${item.orgName}`;
    }
    if (item.program.value.includes("_")) {
      _program = item.program.value.replace("_", "");
    } else {
      _program = item.program.value;
    }

    return {
      ...item,
      _program: _program,
      _seller: _seller,
      _client: `${item.jurOkpo} ${item.jurName}`,
      _state: _state,
      _periodType: termFilter.call(this, item.periodType.value),
      _id: idx + 1,
      // custom search-filter
      _authorForFilter: item.cpAuthor,
      _cpIdForFilter: item.cpId,
      _objectDetailForFilter: item.pledgeDetails
        .map(el => el.objectDetail.nameDesc)
        .join(" "),
      _modelForFilter: item.pledgeDetails.map(el => el.model).join(" "),
      _brandForFilter: item.pledgeDetails.map(el => el.brand).join(" ")
    };
  });
}

function updateOrder(data, orders) {
  let order = data[0];
  let oldOrder = orders.find(item => item.cpId === order.cpId);
  let idx = oldOrder._id - 1;

  order._id = oldOrder._id;
  orders.splice(idx, 1, order);

  return orders;
}

function setTableHeaders() {
  return [
    { text: "№", value: "_id", sortable: false, width: "55px", class: "_id" },
    { text: "", value: "program", sortable: false, class: "program" },
    {
      text: this.$i18n.t("orders.columns.crDt"),
      value: "crDt",
      align: "start",
      class: "crDt"
    },
    {
      text: this.$i18n.t("orders.columns.cpAuthor"),
      value: "cpAuthor",
      class: "cpAuthor"
    },
    {
      text: this.$i18n.t("orders.columns.orgName"),
      value: "orgName",
      class: "orgName"
    },
    {
      text: this.$i18n.t("orders.columns.okpo"),
      value: "_client",
      class: "_client"
    },
    { text: this.$i18n.t("orders.columns.cost"), value: "cost", class: "cost" },
    {
      text: this.$i18n.t("orders.columns.term"),
      value: "_periodType",
      class: "_periodType"
    },
    {
      text: this.$i18n.t("orders.columns.pledgeQuantity"),
      value: "pledgeQuantity",
      class: "pledgeQuantity"
    },
    {
      text: this.$i18n.t("orders.columns.status"),
      value: "_state",
      class: "_state"
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "end",
      width: "65px"
    },
    { text: "", value: "data-table-expand" }
  ];
}
function setTableEsaHeaders() {
  return [
    {
      text: this.$i18n.t("orders.columns.crDt"),
      value: "crDt",
      align: "start",
      class: "crDt"
    },
    { text: "", value: "program", sortable: false, class: "program" },
    {
      text: this.$i18n.t("orders.columns.cpAuthor"),
      value: "cpAuthor",
      class: "cpAuthor"
    },
    {
      text: this.$i18n.t("orders.columns.seller"),
      value: "_seller",
      class: "_seller"
    },
    {
      text: this.$i18n.t("orders.columns.client"),
      value: "_client",
      class: "_client"
    },
    { text: this.$i18n.t("orders.columns.cost"), value: "cost", class: "cost" },
    {
      text: this.$i18n.t("orders.columns.term"),
      value: "_periodType",
      class: "_periodType"
    },
    {
      text: this.$i18n.t("orders.columns.pledgeQuantity"),
      value: "pledgeQuantity",
      class: "pledgeQuantity"
    },
    {
      text: this.$i18n.t("orders.columns.status"),
      value: "_state",
      class: "_state"
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "end",
      width: "65px"
    },
    { text: "", value: "data-table-expand" }
  ];
}

function sendFile(formData, authEsa) {
  if (authEsa) {
    return axios.post(`${router.options.base}esa/cp/saveSpec`, formData);
  } else {
    return axios.post(`${router.options.base}api/saveSpec`, formData);
  }
}

function sendToBank(params, authEsa) {
  if (authEsa) {
    return axios.post(`${router.options.base}esa/cp/sendToBank?${params}`);
  } else {
    return axios.post(`${router.options.base}api/sendToBank?${params}`);
  }
}

function getPdf(cpId, authEsa) {
  if (authEsa) {
    return axios.get(`${router.options.base}esa/getCpPdfReport?cpId=${cpId}`);
  } else {
    return axios.get(`${router.options.base}api/getCpPdfReport?cpId=${cpId}`);
  }
}

function sendLogo(params, formData) {
  return axios.post(
    `${router.options.base}esa/logo/upload?${params}`,
    formData
  );
}

export {
  getPrograms,
  getOrders,
  getById,
  getEsaOrders,
  getEsaOrdersDefaultOptions,
  getHistory,
  createDataForTableOrders,
  updateOrder,
  setTableHeaders,
  setTableEsaHeaders,
  // setTableEsaHeadersPB,
  sendFile,
  sendToBank,
  getPdf,
  sendLogo
};
