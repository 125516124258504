import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import Vuetify from "vuetify/lib";
import VueI18n from "vue-i18n";
import dateFilter from "@/filters/date.filter";
import costFilter from "@/filters/cost.filter";
import gaussRound from "@/filters/gaussRound.filter";
import termFilter from "@/filters/term.filter";
import BaseSnackbar from "@/components/BaseSnackbar";
import { lang } from "@/assets/i18n/localization";

import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import dataModule from "highcharts/modules/data";
import HighchartsVue from "highcharts-vue";

HighchartsMore(Highcharts);
dataModule(Highcharts);

Vue.use(Vuetify);
Vue.use(VueI18n);
Vue.use(HighchartsVue);

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "uk", // set locale default
  messages: lang // set locale messages
});

const vuetify = new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        privat: "#75AF26",
        primary: "#75AF26"
      },
      dark: {
        privat: "#75AF26",
        primary: "#75AF26"
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
});

Vue.config.productionTip = false;

Vue.filter("date", dateFilter);
Vue.filter("cost", costFilter);
Vue.filter("gaussRound", gaussRound);
Vue.filter("term", termFilter);

Vue.component("BaseSnackbar", BaseSnackbar);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
